<template>
    <div id="ed-mainframe">
        <Search/>
        <box-icon class="fullScreen-Icon" @click="makeScreen" name='fullscreen'></box-icon>
         <Layout  :resize="true" :edit="true" :splits="tree">
            <Pane class="split-layout-custom-style">
                <DendroGraphWidget />
            </Pane>
            <Pane class="split-layout-custom-style">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="detail-tab" data-bs-toggle="tab" data-bs-target="#detail" type="button" role="tab" aria-controls="detail" aria-selected="true">Detail</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="videos-tab" data-bs-toggle="tab" data-bs-target="#videos" type="button" role="tab" aria-controls="videos" aria-selected="false">Videos</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="library-tab" data-bs-toggle="tab" data-bs-target="#library" type="button" role="tab" aria-controls="library" aria-selected="false">Graph Library</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="smart-tab" data-bs-toggle="tab" data-bs-target="#smart" type="button" role="tab" aria-controls="smart" aria-selected="false">Smart Graph</button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="detail" role="tabpanel" aria-labelledby="detail-tab">
                  <div class="detail-widget-container">
                            <DetailWidget :showEditMode="true"/>
                        </div>
                </div>
                <div class="tab-pane fade" id="videos" role="tabpanel" aria-labelledby="videos-tab">
                  <div class="detail-widget-container">
                            <VideosWidget/>
                        </div>
                </div>
                <div class="tab-pane fade" id="library" role="tabpanel" aria-labelledby="library-tab">
                  <div class="detail-widget-container">
                            <GraphLibraryWidget/>
                        </div>
                </div>
                <div class="tab-pane fade" id="smart" role="tabpanel" aria-labelledby="smart-tab">
                  <div class="detail-widget-container">
                            <SmartGraphWidget/>
                        </div>
                </div>

              </div>
            </Pane>
        </Layout>
    </div>
</template>

<script>
import { Layout, Pane } from 'vue-split-layout';

import DendroGraphWidget from '@/common/widgets/DendroGraphWidget/DendroGraphWidget.vue';
import DetailWidget from '@/common/widgets/DetailWidget/DetailWidget.vue';
import VideosWidget from '@/common/widgets/VideosWidget/VideosWidget.vue';
import GraphLibraryWidget from '@/common/widgets/GraphLibraryWidget/GraphLibraryWidget.vue';
import SmartGraphWidget from '@/common/widgets/SmartGraphWidget/SmartGraphWidget.vue';
import Search from '../Search.vue';

export default {
  components: {
    DendroGraphWidget,
    DetailWidget,
    VideosWidget,
    GraphLibraryWidget,
    SmartGraphWidget,
    Search,
    Layout,
    Pane,
  },
  data() {
    return {
      tree: {
        dir: 'horizontal',
        first: 0,
        second: {
          dir: 'vertical', // Left | Right
          // Other split
          first: 1,
          second: 2,
          split: '100%',
        },
        split: '70%',
      },

      layoutKeys: ['_a', '_b'],
    };
  },
  mounted() {
    this.$store.dispatch('quests/setQuestMode', true);
  },
  methods: {
    makeScreen(el) {
      if (document.fullscreen) {
        document.exitFullscreen();
      } else {
        console.log(el);
        el.target.parentElement.requestFullscreen();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fullScreen-Icon {
  position: absolute;
  bottom: 20px;
  right: 20px;
  z-index: 6;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
}

.split-layout-custom-style {
  height: 100%;

  .nav-tabs {
    padding: 10px 10px 0 10px;
    background-color: #f8f9fa;
  }

  .tab-content {
    height: calc(100% - 50px); // Subtract nav-tabs height
    padding: 15px;

    .tab-pane {
      height: 100%;
    }
  }

  .content {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

#ed-mainframe {
  position: relative;
  height: 100vh;

  :deep(.search-container) {
    margin-top: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}

.detail-widget-container {
  height: 100%;
  padding: 10px;
  background-color: white;
}
</style>
